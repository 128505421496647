/* Webkit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #000033;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #ffd700;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #000033 #f1f1f1;
}

/* For Internet Explorer and Edge */
body {
  -ms-overflow-style: none;
}

/* Hide default scrollbar for IE, Edge and Firefox */
body {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

/* Show custom scrollbar */
body::-webkit-scrollbar {
  display: block;
}

.projects-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Project Card styles */
.project-card {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 1.5em;
  color: #0073e6;
  margin-bottom: 10px;
}

.section {
  margin-top: 10px;
}

.section h3 {
  color: #333;
  margin-bottom: 5px;
}

.section p,
.section ul {
  margin-left: 20px;
  color: #555;
}

ul {
  list-style-type: disc;
}
